const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// rails-ujs
import Rails from '@rails/ujs'
Rails.start();

// loads the Icon plugin
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
UIkit.use(Icons);

import Routes from '../src/javascripts/js_routes.js.erb'

import '../src/javascripts/main/dnd-polyfill';

import '../src/stylesheets/main'

const QueryString = require('query-string');

let handleSubmit = function () {
  UIkit.util.on('#submit', 'click', function (e) {
    e.preventDefault();

    Rails.ajax({
      type: "POST",
      dataType: 'json',
      url: Routes.customer_session_path(),
      data: (function () {
        let data = `authenticity_token=${document.querySelector("input[name='authenticity_token']").value}`;
        data += `&customer[login]=${document.getElementById('customer_login').value}`;
        data += `&customer[password]=${document.getElementById('customer_password').value}`;
        if (document.getElementById('customer_remember_me')) {
          data += `&customer[remember_me]=${document.getElementById('customer_remember_me').checked ? 1 : 0}`;
        }
        return data;
      }()),
      success: function (response) {
        location.href = response.location;
      },
      error: function (response) {
        document.getElementById('alert').innerHTML = `<div class="padding-top"><div class="uk-alert uk-alert-danger" uk-alert><p>${response.error}</p></div></div>`;
        document.getElementById('submit').disabled = false;
      }
    });
  });
};

let dialog = null;

if (document.getElementById('keyhole')) {
  let showDialog = function () {
    if (dialog === null) {
      Rails.ajax({
        type: "GET",
        dataType: 'script',
        url: Routes.new_customer_session_path(),
        success: function (response) {
          let html = '<div id="sign-in-dialog" class="uk-flex-top">';
          html += '<div class="uk-modal-dialog uk-margin-auto-vertical">';
          if (window.formContent) {
            html += window.formContent;
          }
          html += '</div>';
          html += '</div>';

          dialog = UIkit.modal(html);

          dialog.show();

          UIkit.util.on('#sign-in-dialog', 'hidden', function (e) {
            e.preventDefault();
            e.target.blur();
            document.getElementById('customer_login').blur();
            document.getElementById('customer_password').blur();
          //  customer_login customer_password
          });

          handleSubmit();
        }
      });
    } else {
      dialog.show();
    }
  };

  UIkit.util.on('#keyhole', 'dragenter', function (e) {
    e.preventDefault();
  });
  UIkit.util.on('#keyhole', 'dragover', function (e) {
    e.preventDefault();
  });
  UIkit.util.on('#keyhole', 'drop', function (e) {
    e.preventDefault();

    let id = e.dataTransfer.getData("id");

    if (id === 'key') {
      e.preventDefault();
      showDialog();
    }
  });

  UIkit.util.on('#key', 'dragstart', function (e) {
    e.dataTransfer.setData("id", e.target.id);
    document.getElementById('keyhole').hidden = false;
  });
  UIkit.util.on('#key', 'dragend', function (e) {
    e.preventDefault();
    document.getElementById('keyhole').hidden = true;
  });

  let query = QueryString.parse(location.search);

  if ('login' in query) {
    showDialog();
  }
} else {
  handleSubmit();
}
